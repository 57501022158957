import React from "react"
import { ZYN_ALT } from "../../../constants/Constants"

const image = require("../../../images/zyn/content1-mobile.png")

const Content1MobileImage = props => {
  return <img src={image} alt={ZYN_ALT} {...props} />
}

export default Content1MobileImage
