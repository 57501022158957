import React from "react"
import { ZYN_ALT } from "../../../constants/Constants"

const image = require("../../../images/zyn/content5-mobile.jpg")

const Content5MobileImage = props => {
  return <img src={image} alt={ZYN_ALT} {...props} />
}

export default Content5MobileImage
