import React from "react"
import { ZYN_ALT } from "../../../constants/Constants"

const image = require("../../../images/zyn/screenshot2-mobile.png")

const Screenshot2MobileImage = props => {
  return <img src={image} alt={ZYN_ALT} {...props} />
}

export default Screenshot2MobileImage
