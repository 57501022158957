import React from "react"
import { ZYN_ALT } from "../../../constants/Constants"

const image = require("../../../images/zyn/background.png")

const BackgroundImage = props => {
  return <img src={image} alt={ZYN_ALT} {...props} />
}

export default BackgroundImage
