import React from "react"
import { ZYN_ALT } from "../../../constants/Constants"

const image = require("../../../images/zyn/screenshot1.png")

const Screenshot1Image = props => {
  return <img src={image} alt={ZYN_ALT} {...props} />
}

export default Screenshot1Image
