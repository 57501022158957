import React from "react"
import ReactCompareImage from "react-compare-image";
import IntlText from "../../components/common/intl-text-2";
import Text from "../../components/common/text";
import WebIcon from "../../components/icons/WebIcon";
import Layout from "../../components/layout";
import ProjectContainer from "../../components/projects/project-container";
import ProjectContent from "../../components/projects/project-content";
import ProjectIntro from "../../components/projects/project-intro";
import BackgroundImage from "../../components/projects/zyn-images/background";
import Content1Image from "../../components/projects/zyn-images/content-1";
import Content1MobileImage from "../../components/projects/zyn-images/content-1-mobile";
import Content2MobileImage from "../../components/projects/zyn-images/content-2-mobile";
import Content3Image from "../../components/projects/zyn-images/content-3";
import Content3MobileImage from "../../components/projects/zyn-images/content-3-mobile";
import Content4Image from "../../components/projects/zyn-images/content-4";
import Content5Image from "../../components/projects/zyn-images/content-5";
import Content5MobileImage from "../../components/projects/zyn-images/content-5-mobile";
import Content6Image from "../../components/projects/zyn-images/content-6";
import LogoImage from "../../components/projects/zyn-images/logo";
import Screenshot1Image from "../../components/projects/zyn-images/screenshot-1";
import Screenshot1MobileImage from "../../components/projects/zyn-images/screenshot-1-mobile";
import Screenshot2Image from "../../components/projects/zyn-images/screenshot-2";
import Screenshot2MobileImage from "../../components/projects/zyn-images/screenshot-2-mobile";
import { ZYN_ALT } from "../../constants/Constants";

const tags = [
  'Product Building',
  'UX / UI',
  'Frontend'
];

const stores = [{
  store: 'web',
  icon: <WebIcon />,
  link: 'https://zyn.ca'
}];

const technologies = "React, Gatsby, Shopify";

const before1 = require("src/images/zyn/compare-before-1.png")
const after1 = require("src/images/zyn/compare-after-1.png")

const HowWeHelp = () => {
  return (
    <div className="row how-we-help">
      <div className="col-12 col-lg-6 left-column">
        <IntlText id="zyn.body2" component="h1" />
      </div>
      <div className="col-12 col-lg-6 right-column">
        <IntlText id="zyn.body3" component="h2" />
      </div>
    </div>
  );
}

const WhatWeDo = () => {
  return (
    <div className="row what-we-do">
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="technologies" />
        <Text>
          <strong>{technologies}</strong>
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="platforms" />
        <Text>
          <IntlText id="zyn.platforms" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="services" />
        <Text>
          <IntlText id="zyn.services" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="projectContent.explore" />
        <div className="store-icons">
          {
            stores.map((value, key) => (
              <a key={key} href={value.link} target="_blank" rel="noopener noreferrer" title={value.store}>
                {value.icon}
              </a>
            ))
          }
        </div>
      </div>
    </div>
  );
}

const Screenshots = () => {
  return (
    <div className="screenshots-container zyn">
      <div className="images">
        <Screenshot1Image className="screenshot-img d-none d-sm-block" />
        <Screenshot1MobileImage className="screenshot-img mobile mobile-first" />
        <Screenshot2Image className="screenshot-img d-none d-sm-block" />
        <Screenshot2MobileImage className="screenshot-img mobile" />
      </div>
    </div>
  );
}

const Compare = () => {
  return (
    <div className="compare-image d-none d-md-block">
      <div className="compare-titles">
        <IntlText id="before" component="span" />
        <IntlText id="after" component="span" />
      </div>
      <ReactCompareImage leftImage={before1} rightImage={after1} leftImageAlt={ZYN_ALT} rightImageAlt={ZYN_ALT} />
    </div>
  )
}

const ZynPage = () => {
  return (
    <Layout
      pageTitle="Projects"
      title="Zyn´s  software development challenge - Sysgarage"
      description="Check out the software development project for Zyn that our company has successfully tackled. ​Let us show you how we can help!"
      hideHeader
      noMargin
      hideContact
    >
      <ProjectContainer>
        <div className="sg-container">
          <ProjectIntro
            tags={tags}
            logo={<LogoImage className="logo-image" />}
            textId="zyn.body1"
            background={<BackgroundImage className="background-image" />}
          />
          <HowWeHelp />
          <WhatWeDo />
        </div>
        <ProjectContent
          titleId="zyn.body4"
          descriptionId="zyn.body5"
          image={<Content1Image />}
          imageMobile={<Content1MobileImage />}
        />
        <ProjectContent
          titleId="zyn.body6"
          descriptionId="zyn.body7"
          imageMobile={<Content2MobileImage />}
        />
        <Compare />
        <ProjectContent
          titleId="zyn.body8"
          descriptionId="zyn.body9"
          image={<Content3Image />}
          imageMobile={<Content3MobileImage />}
        />
        <ProjectContent
          titleId="zyn.body10"
          descriptionId="zyn.body11"
          image={<Content4Image />}
          imageMobile={<Content4Image />}
        />
        <ProjectContent
          titleId="zyn.body12"
          descriptionId="zyn.body13"
          image={<Content5Image />}
          imageMobile={<Content5MobileImage />}
        />
        <ProjectContent
          titleId="zyn.body14"
          descriptionId="zyn.body15"
          image={<Content6Image />}
          imageMobile={<Content6Image />}
        />
        <ProjectContent>
          <Screenshots />
          <div className="blank-space" />
        </ProjectContent>
      </ProjectContainer>
    </Layout>
  );
}

export default ZynPage
